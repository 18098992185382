import { Pipe, type PipeTransform } from '@angular/core';

import type { Consultation } from '@mbeon-pwa/domain';

@Pipe({
  name: 'latestConsultation',
  pure: true,
  standalone: true,
})
export class LatestConsultationPipe implements PipeTransform {
  transform(array: readonly Consultation[]) {
    const consultationInfos: Consultation[] = [...array];

    consultationInfos.sort((a: Consultation, b: Consultation): -1 | 0 | 1 => {
      const aTime: number | undefined =
        a.statistics.lastMessageTimestamp?.getTime();
      const bTime: number | undefined =
        b.statistics.lastMessageTimestamp?.getTime();

      if (aTime && !bTime) {
        return 1;
      } else if (!aTime && bTime) {
        return -1;
      } else if (!aTime && !bTime) {
        return 0;
      }

      if (aTime! < bTime!) {
        return 1;
      } else if (aTime! > bTime!) {
        return -1;
      }

      return 0;
    });

    return consultationInfos;
  }
}
