<ion-item button="true" class="chat-list-element" detail="false" lines="none">
  <ion-avatar slot="start">
    <ion-skeleton-text [animated]="true"></ion-skeleton-text>
  </ion-avatar>
  <ion-label>
    <div class="content-wrapper">
      <h3 style="width: 100%">
        <ion-skeleton-text
          [animated]="true"
          style="width: 80%"
        ></ion-skeleton-text>
      </h3>
    </div>
    <p>
      <ion-skeleton-text
        [animated]="true"
        style="width: 60%"
      ></ion-skeleton-text>
    </p>
  </ion-label>
</ion-item>
