import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItemSliding,
  IonList,
  IonMenu,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

import { type Consultation } from '@mbeon-pwa/domain';

import { TranslocoDirective } from '@ngneat/transloco';

import { RxState } from '@rx-angular/state';
import { RxFor } from '@rx-angular/template/for';
import { RxLet } from '@rx-angular/template/let';

import { defer, type Observable } from 'rxjs';

import { NavBackButtonComponent } from '../../../../common/components/buttons/nav-back-button/nav-back-button.component';
import { LatestConsultationPipe } from '../../pipes/latest-consultation/latest-consultation.pipe';
import { ChatListElementComponent } from '../chat-list-element/chat-list-element.component';
import { ChatListElementSkeletonComponent } from '../chat-list-element-skeleton/chat-list-element-skeleton.component';

import { type ChatListComponentState } from './chat-list.component.state.type';

@Component({
  selector: 'mbeon-pwa-chat-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ChatListElementComponent,
    ChatListElementSkeletonComponent,
    CommonModule,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItemSliding,
    IonList,
    IonMenu,
    IonSplitPane,
    IonTitle,
    IonToolbar,
    LatestConsultationPipe,
    NavBackButtonComponent,
    RxFor,
    RxLet,
    TranslocoDirective,
  ],
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss'],
  viewProviders: [RxState],
})
export class ChatListComponent {
  @Input({
    required: true,
  })
  set chatList(consultations: readonly Consultation[]) {
    this.#state.set({
      consultations,
    });
  }

  readonly state$: Observable<ChatListComponentState> = defer(
    (): Observable<ChatListComponentState> => this.#state.select(),
  );

  readonly #state: RxState<ChatListComponentState> =
    inject<RxState<ChatListComponentState>>(RxState);

  identifyConsultation(index: number, consultation: Consultation): string {
    return consultation.consultant.id;
  }
}
