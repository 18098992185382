<ion-item
  *rxLet="state$; let state"
  [class.input-invalid]="!!state.error"
  [class.on-focus]="state.hasFocus"
>
  <ion-input
    labelPlacement="floating"
    [clearOnEdit]="state.clearOnEdit"
    [attr.aria-label]="state.label"
    [label]="state.label"
    [formControl]="control"
    [type]="state.displayType"
    (ionFocus)="toggleFocus(true)"
    (ionBlur)="toggleFocus(false)"
  ></ion-input>

  <ion-button
    fill="clear"
    slot="end"
    *ngIf="state.type === 'password'"
    (click)="toggleShowPassword($event)"
  >
    <ion-icon
      name="eye"
      slot="icon-only"
      *ngIf="state.displayType === 'password'"
    ></ion-icon>
    <ion-icon
      name="eye-off"
      slot="icon-only"
      *ngIf="state.displayType !== 'password'"
    ></ion-icon>
  </ion-button>

  <ion-button
    fill="clear"
    slot="end"
    *ngIf="state.canClearInput && !!control.value"
    (click)="clearValue()"
  >
    <ion-icon name="close-circle" slot="icon-only"></ion-icon>
  </ion-button>
</ion-item>
