import { Pipe, PipeTransform } from '@angular/core';
import sanitize from 'sanitize-html';

@Pipe({
  name: 'sanitize',
  standalone: true,
})
export class SanitizePipe implements PipeTransform {
  transform(value = ''): string {
    const decodedString: string = this.#decodeHtmlString(value);

    return sanitize(decodedString, {
      allowedTags: [],
      allowedAttributes: {},
    });
  }

  #decodeHtmlString(htmlString: string): string {
    const doc: Document = new DOMParser().parseFromString(
      htmlString,
      'text/html',
    );
    return doc.documentElement.textContent ?? '';
  }
}
