<ion-list *rxLet="state$; let state">
  <mbeon-pwa-chat-list-element
    *rxFor="
      let consultation of state.consultations | latestConsultation;
      trackBy: identifyConsultation
    "
    [id]="consultation.consultant.id"
    [isVoiceMessage]="
      consultation.statistics.lastMessage?.isVoiceMessage ?? false
    "
    [lastMessageTimestamp]="consultation.statistics.lastMessageTimestamp"
    [imageUrl]="consultation.consultant.avatar"
    [lastMessage]="consultation.statistics.lastMessage?.text ?? undefined"
    [lastName]="consultation.consultant.lastname"
    [name]="consultation.consultant.firstname"
    [unreadMessages]="consultation.hasUnreadMessage"
    [username]="consultation.consultant.username"
  ></mbeon-pwa-chat-list-element>
</ion-list>
