import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import {
  IonAvatar,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonSkeletonText,
} from '@ionic/angular/standalone';

import { RxLet } from '@rx-angular/template/let';

@Component({
  selector: 'mbeon-pwa-chat-list-element-skeleton',
  standalone: true,
  imports: [
    CommonModule,
    IonIcon,
    IonItemSliding,
    IonAvatar,
    IonLabel,
    RxLet,
    NgOptimizedImage,
    IonItemOptions,
    IonItemOption,
    IonItem,
    IonSkeletonText,
  ],
  templateUrl: './chat-list-element-skeleton.component.html',
  styleUrl: './chat-list-element-skeleton.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatListElementSkeletonComponent {}
