<ng-container *transloco="let t">
  <ion-item-sliding *rxLet="state$; let state">
    <div class="new-notification-marker" *ngIf="state.unreadMessages"></div>

    <ion-item
      button="true"
      class="chat-list-element"
      detail="false"
      lines="none"
      [class.new-notification]="state.unreadMessages"
      [routerLink]="[state.id]"
    >
      <ion-avatar slot="start">
        <img *ngIf="state.image" [src]="state.image" alt="avatar" />
        <img
          alt="avatar"
          class="fallback-avatar"
          src="assets/img/avatar_mbeon_logo.svg"
          *ngIf="!state.image"
        />
      </ion-avatar>

      <ion-label>
        <div class="content-wrapper">
          <h3>
            {{
              state.username | name: state.name : state.lastName | truncate: 22
            }}
          </h3>
          <div class="date-content">
            <span class="pr-1 date" *rxIf="!!state.lastMessageTimestamp">{{
              state.lastMessageTimestamp! | date: 'dd.MM.yyyy'
            }}</span>
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </div>
        </div>
        <p *rxIf="!state.isVoiceMessage">
          {{ state.lastMessage | sanitize | truncate: 28 }}
        </p>
        <p *rxIf="state.isVoiceMessage">
          {{ t('chat-thread.message.is-voice-message') }}
        </p>
      </ion-label>
    </ion-item>
  </ion-item-sliding>
</ng-container>
