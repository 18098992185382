import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterLinkWithHref } from '@angular/router';

import {
  IonAvatar,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
} from '@ionic/angular/standalone';

import { RxState } from '@rx-angular/state';
import { RxIf } from '@rx-angular/template/if';
import { RxLet } from '@rx-angular/template/let';

import { addIcons } from 'ionicons';
import { chevronForwardOutline, trashBin } from 'ionicons/icons';

import { defer, Observable } from 'rxjs';

import { TranslocoDirective } from '@ngneat/transloco';

import { NamePipe } from '../../../../common/pipes/name/name.pipe';
import { TruncatePipe } from '../../../../common/pipes/tuncate/truncate.pipe';

import { SanitizePipe } from '../../pipes/sanitize/sanitize.pipe';

import { ChatListElementComponentState } from './chat-list-element.component.state';

@Component({
  selector: 'mbeon-pwa-chat-list-element',
  standalone: true,
  imports: [
    CommonModule,
    IonAvatar,
    IonIcon,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    NamePipe,
    NgOptimizedImage,
    RouterLinkWithHref,
    RxIf,
    RxLet,
    TruncatePipe,
    TranslocoDirective,
    SanitizePipe,
  ],
  templateUrl: './chat-list-element.component.html',
  styleUrl: './chat-list-element.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [RxState],
})
export class ChatListElementComponent {
  @Input({
    required: true,
  })
  set id(id: string) {
    this.#state.set({
      id,
    });
  }

  @Input()
  set isVoiceMessage(isVoiceMessage: boolean) {
    this.#state.set({
      isVoiceMessage,
    });
  }

  @Input({
    required: true,
  })
  set username(username: string) {
    this.#state.set({
      username,
    });
  }

  @Input()
  set lastMessageTimestamp(lastMessageTimestamp: Date | undefined) {
    this.#state.set({
      lastMessageTimestamp,
    });
  }

  @Input()
  set lastName(lastName: string | undefined) {
    this.#state.set({
      lastName,
    });
  }

  @Input()
  set lastMessage(lastMessage: string | undefined) {
    this.#state.set({
      lastMessage,
    });
  }

  @Input()
  set name(name: string | undefined) {
    this.#state.set({
      name,
    });
  }

  @Input({
    required: true,
  })
  set unreadMessages(unreadMessages: boolean) {
    this.#state.set({
      unreadMessages,
    });
  }

  @Input()
  set imageUrl(image: string) {
    this.#state.set({
      image,
    });
  }

  readonly state$: Observable<ChatListElementComponentState> = defer(
    (): Observable<ChatListElementComponentState> => this.#state.select(),
  );

  readonly #state: RxState<ChatListElementComponentState> =
    inject<RxState<ChatListElementComponentState>>(RxState);

  constructor() {
    addIcons({ chevronForwardOutline, trashBin });

    this.#state.set({
      isVoiceMessage: false,
    });
  }
}
