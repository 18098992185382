import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonItem, IonList } from '@ionic/angular/standalone';

import { RxState } from '@rx-angular/state';

import { RxLet } from '@rx-angular/template/let';

import { Observable } from 'rxjs';

import { ChatListElementSkeletonComponent } from '../chat-list-element-skeleton/chat-list-element-skeleton.component';

import { ChatListSkeletonComponentState } from './chat-list-skeleton.component.state';

@Component({
  selector: 'mbeon-pwa-chat-list-skeleton',
  standalone: true,
  imports: [
    CommonModule,
    IonList,
    ChatListElementSkeletonComponent,
    RxLet,
    IonItem,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './chat-list-skeleton.component.html',
  styleUrl: './chat-list-skeleton.component.scss',
  viewProviders: [
    {
      provide: RxState,
      useFactory: (): RxState<ChatListSkeletonComponentState> => new RxState(),
    },
  ],
})
export class ChatListSkeletonComponent {
  #chatListSkeletonComponentState: RxState<ChatListSkeletonComponentState> =
    inject<RxState<ChatListSkeletonComponentState>>(RxState);

  @Input({
    required: true,
  })
  set itemCount(itemCount: number) {
    this.#chatListSkeletonComponentState.set({
      itemCount: [...new Array(itemCount)],
    });
  }

  get state$(): Observable<ChatListSkeletonComponentState> {
    return this.#chatListSkeletonComponentState.select();
  }
}
