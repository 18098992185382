import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
} from '@ionic/angular/standalone';

import { RxLet } from '@rx-angular/template/let';
import { RxState } from '@rx-angular/state';

import { addIcons } from 'ionicons';
import { closeCircle, eye, eyeOff } from 'ionicons/icons';

import { defer, type Observable } from 'rxjs';

import type { InputFormFieldComponentState } from './input-form-field.component.state';

@Component({
  standalone: true,
  selector: 'mbeon-pwa-input-form-field',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IonButton,
    IonIcon,
    IonInput,
    IonItem,
    FormsModule,
    RxLet,
    ReactiveFormsModule,
  ],
  templateUrl: './input-form-field.component.html',
  styleUrls: ['./input-form-field.component.scss'],
  viewProviders: [
    {
      provide: RxState,
      useFactory: (): RxState<InputFormFieldComponentState> => new RxState(),
    },
  ],
})
export class InputFormFieldComponent {
  @Input()
  set clearInput(canClearInput: boolean) {
    this.#state.set({
      canClearInput,
    });
  }

  @Input()
  set ClearOnEdit(clearOnEdit: boolean) {
    this.#state.set({
      clearOnEdit,
    });
  }

  @Input({
    required: true,
  })
  set control(control: FormControl) {
    this.#control = control;
  }

  get control(): FormControl {
    return this.#control;
  }

  @Input({
    required: true,
  })
  set label(label: string) {
    this.#state.set({
      label,
    });
  }

  @Input()
  set hasError(error: boolean) {
    this.#state.set({
      error,
    });
  }

  @Input()
  set type(type: 'text' | 'number' | 'password') {
    this.#state.set({
      displayType: type,
      type,
    });
  }

  readonly state$: Observable<InputFormFieldComponentState> = defer(
    (): Observable<InputFormFieldComponentState> => this.#state.select(),
  );

  readonly #state: RxState<InputFormFieldComponentState> =
    inject<RxState<InputFormFieldComponentState>>(RxState);

  #control!: FormControl;

  constructor() {
    addIcons({
      closeCircle,
      eye,
      eyeOff,
    });

    this.#state.set({
      displayType: 'text',
      hasFocus: false,
      error: false,
      clearOnEdit: false,
    });
  }

  clearValue(): void {
    this.#control.reset();
    this.#control.updateValueAndValidity();
  }

  toggleFocus(hasFocus: boolean): void {
    this.#state.set({
      hasFocus,
    });
  }

  toggleShowPassword(event: Event): void {
    event.stopPropagation();

    this.#state.set({
      displayType:
        this.#state.get().displayType === 'password' ? 'text' : 'password',
    });
  }
}
