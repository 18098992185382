import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true,
  pure: true,
})
export class TruncatePipe implements PipeTransform {
  transform(value = '', length: number, truncateStart = false): string {
    if (truncateStart && value.length > length) {
      return `...${value.substring(value.length - length - 1)}`;
    } else if (value.length > length) {
      return `${value.substring(0, length)}...`;
    }

    return value;
  }
}
